import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`
const distributionlistService =
{

    findDistributionList(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
            { query: 'eventtype', val: payload.eventType },
        ]
        return http.get(`${API_ROOT}/supplier/distribution-list${param(arr)}`).then(resp => {
            return resp
        })
    },
    saveDistributionList(payload,eventType) {
        payload.eventType = eventType
        return http.post(`${API_ROOT}/supplier/distribution-list/save`, payload).then(resp => {
            return resp
        })
    },

    getDistributionList(id) {
        return http.get(`${API_ROOT}/supplier/distribution-list/${id}`).then(resp => {
            return resp
        })
    },
    changeStatus(id) {
        return http.put(`${API_ROOT}/supplier/distribution-list/change-status/${id}`).then(resp => {
            return resp
        })
    },
}

export default distributionlistService