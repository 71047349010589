import  {TYPICAL_STATUS} from '../../../misc/commons'
import DistributionModel from '../distribution-list/DistributionModel'
import providerService from '../../../services/provider/distribution-list'
import supplierService from '../../../services/supplier/distribution-list'
import store from '../../../store'
import { DistributionLists_Headers } from '../../../misc/tableHeaders'
export default {
    components: {
        DistributionModel
    },
    props: {
        eventType: { type: String, default: 'RECALL'},
    },
    data() {
        return {
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
                suppressHorizontalScroll: true,
            },
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            gridApi: null,
            columnApi: null,
            total: 0,
            items: [],
            status: 'ALL',
            search: {
                name: null,
                recipients: null,
                status: null,
            },
            typical_status: TYPICAL_STATUS,
            distributionModelKey: 0,
            orgType: store.state.userType,
            service: store.state.userType =='SUPPLIER' ? supplierService: providerService,
        }
    },
    beforeMount() {
        this.columnDefs = DistributionLists_Headers
    },
    created() {
        this.rowModelType = 'serverSide'
    },
    methods: {
        onCellClicked(params){
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'inactive' || params.event.target.dataset.action === 'active') {
                    this.service.changeStatus(params.data.id).then(resp => {
                        if (!resp.error) {
                            if (params.data.status=='ACTIVE' ) {
                                this._showToast('Distribution list deactivated successfully!')
                            } else {
                                this._showToast('Distribution list Activated successfully!')
                            }
                            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
                        }
                    })
                }
                if (params.event.target.dataset.action === 'edit') {
                    this.distributionModel += 1
                    this.$nextTick(()=>{
                        this.$refs.distributionModel.forceRerenderUpdate(params.data.id)
                    })
                }
                return
            }else{
                this.distributionModel += 1
                this.$nextTick(()=>{
                    this.$refs.distributionModel.forceRerenderView(params.data.id)
                })
            }
        },
        onCompleted() {
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
        },
        addMoreClick() {
            this.distributionModel += 1
            this.$nextTick(()=>{
                this.$refs.distributionModel.forceRerenderInsert(this.eventType)
            })
        },
        onFilterChanged(event) {
            this.fetchData(event)
        },
        onSortChanged(event) {
            this.fetchData(event)
        },
        fetchData(params) {
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            const datasource = {
                getRows(params){
                    that.search.name = params.request.filterModel.name?.filter
                    that.search.recipients = params.request.filterModel.recipients?.filter
                    that.search.status = params.request.filterModel.status?.filter
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.filterModel = that.gridApi.getFilterModel()
                    that.service.findDistributionList({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        eventType: that.eventType,
                        ...that.search
                    }).then(resp=>{
                        if (!resp.error) {
                            that.total = resp.data.d.t
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            return resp.data.d.c
                        }
                    }).then(response => {
                        params.successCallback(response)
                    }).catch(error => {
                        console.error(error)
                        params.failCallback()
                    })
                }
            }
            params.api.setServerSideDatasource(datasource)
        },

        edit(item,e){
            e.stopPropagation()
            this.distributionModel += 1
            this.$nextTick(()=>{
                this.$refs.distributionModel.forceRerenderUpdate(item.id)
            })

        },
        view(item){
            this.distributionModel += 1
            this.$nextTick(()=>{
                this.$refs.distributionModel.forceRerenderView(item.id)
            })
        },
        changeStatus(item, e) {
            this.service.changeStatus(item.id).then(resp => {
                if (!resp.error) {
                    if (item.status=='ACTIVE' ) {
                        this._showToast('Distribution list deactivated successfully!')
                    } else {
                        this._showToast('Distribution list Activated successfully!')
                    }
                    this.fetchData()
                }
            })
            e.stopPropagation()
        }


    }
}